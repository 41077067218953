
import { defineComponent, onMounted } from 'vue';
import MixedWidget12 from '@/components/widgets/mixed/Widget12.vue';
import MixedWidget7 from '@/components/widgets/mixed/Widget7.vue';
import MixedWidget10 from '@/components/widgets/mixed/Widget10.vue';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';

export default defineComponent({
  name: 'dashboard',
  components: {
    MixedWidget10,
    MixedWidget12,
    MixedWidget7,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs('Dashboard', ['Overview']);
    });
  },
});
